.flex-outer,
.flex-inner {
  list-style-type: none;
  padding: 0;
}

.flex-outer {
  max-width: 800px;
  margin: 0 auto;
}

.flex-outer li,
.flex-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex-outer > li:not(:last-child) {
  margin-bottom: 20px;
}


.container-budget-form {
  max-width: 1200px;
  margin: 0 auto;
}

.container-familia-edicao * {
  box-sizing: border-box;
}

.line-2-columns{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-dialog-buttons{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: right;
}

.button{
  margin: 2px;
  width: 170px;
}

.line-buttons{
  display: flex;
  justify-content: space-between;
  width: 200px;
  position: relative;
  top: 16px;
  right: -16px;
}